/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from '../../components/HelmetHelper'
import Container from '../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../components/content/Text'
import {
	THEME_TAUPE,
	THEME_GREEN,
	THEME_WHITE,
	colors,
	media,
} from '../../constants'
import Slide, {
	SLIDE_LAYOUT_MOCK_FULL_SCREEN,
	SLIDE_LAYOUT_FULL_BLEED_IMAGE,
} from '../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutCol2,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../../components/content/Layout'
import FullBleedImage from '../../components/content/FullBleedImage'
import { InlineLink, StandaloneLink } from '../../components/content/Link'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import Highlight from '../../components/content/Highlight'
import VideoLoop, {
	VIDEO_CLIP_AUTOPLAY,
} from '../../components/content/VideoLoop'
import Showcase, {
	SHOWCASE_LAYOUT_1UP,
	SHOWCASE_LAYOUT_3UP,
	SHOWCASE_LAYOUT_2UP,
} from '../../components/content/Showcase'

import { caseStudyLinkData } from '../../workData'

const nextPage = caseStudyLinkData.ebay.next

const EBayPage = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			homepage: file(relativePath: { eq: "ebay/ebay-playbook-home@2x.png" }) {
				...ImageFluidFull
			}
			contentPage1: file(relativePath: { eq: "ebay/ebay-content-01@2x.png" }) {
				...ImageFluidFull
			}
			contentPage2: file(relativePath: { eq: "ebay/ebay-content-02@2x.png" }) {
				...ImageFluidFull
			}
			contentPage3: file(relativePath: { eq: "ebay/ebay-content-03@2x.png" }) {
				...ImageFluidFull
			}

			colorBlocker1: file(
				relativePath: { eq: "ebay/ebay-color-blocker-01@2x.png" }
			) {
				...ImageFluidFull
			}
			colorBlocker2: file(
				relativePath: { eq: "ebay/ebay-color-blocker-02@2x.png" }
			) {
				...ImageFluidFull
			}

			colorLayeringTool: file(
				relativePath: { eq: "ebay/ebay-color-layering@2x.png" }
			) {
				...ImageFluidFull
			}
			assetMaker: file(relativePath: { eq: "ebay/ebay-asset-maker@2x.png" }) {
				...ImageFluidFull
			}
		}
	`)

	return (
		<div>
			<Helmet>
				<title>eBay</title>
				<meta content="eBay Case Study" property="og:title" />
				<meta content="eBay Case Study" name="twitter:title" />
				<meta
					property="og:description"
					content="Free Association was brought in to create a single source of truth for all things brand — a digital platform called eBay Playbook, designed to speed up decisions across the organization."
				/>
				<meta
					name="description"
					content="Free Association was brought in to create a single source of truth for all things brand — a digital platform called eBay Playbook, designed to speed up decisions across the organization."
				/>
				<meta
					content="https://freeassociation.com/share/ebay.png"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/ebay.png"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--eyebrow">eBay</h1>
							<p className="--headline">
								A Playbook for brand decision-making.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									eBay set a bold new vision for their brand and company
									strategy in 2018, but needed help providing teams across the
									world with easy access, up-to-date info, and the clarity to
									execute with confidence. Free Association was brought in to
									create a single source of truth for all things brand — a
									digital platform called eBay Playbook, designed to speed up
									decisions across the organization and unite 15,000+ employees
									and agency partners.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Services">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled-mediumUp --col-2">
									<li>Product Leadership</li>
									<li>Strategic Planning</li>
									<li>Project Management</li>
									<li>UX &amp; Market Research</li>
									<li>Testing &amp; Measurement</li>
									<li>Information Architecture</li>
									<li>User Experience Design</li>
									<li>UI &amp; Visual Design</li>
									<li>Interaction Prototyping</li>
									<li>Brand Systems &amp; Guidelines</li>
									<li>Technical Strategy</li>
									<li>UX Prototyping</li>
									<li>Web Development</li>
									<li>Design Tools</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.homepage.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									For nearly three years, we've collaborated closely to make
									eBay Playbook an elegant, useful and scalable platform that
									helps bring the brand to life.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Brand Building Blocks">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										We formed a content strategy, then reshaped comprehensive
										guidelines to clarify Strategy, Logo, Color, Typography,
										Photography, Iconography, Layout and Voice & Tone.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Executional Guidelines">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										We helped various teams build guidelines specific to their
										channels, tying all back to brand foundations — digital
										product, marketing creative, branded merchandise and more.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Brand Resources">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										We centralized many key resources, including a Brand
										Showcase of the best execution worldwide, an Asset Library
										with all downloads, and a Glossary to build a shared
										language.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Community Feedback &amp; Visibility">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										We established direct lines of communication via ZenDesk, so
										any user could easily contribute or ask questions, and the
										platform could represent known issues and updates in
										progress.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						layout={SHOWCASE_LAYOUT_3UP}
						images={[
							data.contentPage1.childImageSharp.fluid,
							data.contentPage2.childImageSharp.fluid,
							data.contentPage3.childImageSharp.fluid,
						]}
					/>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							When rules got too complex, we built design guidance tools. The
							eBay brand palette has 58 colors and a seamingly endless number of
							combination possibilities. One tool helped users create
							brand-approved color combinations, while the other generated color
							block layouts.
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={3604} height={2124} mode={VIDEO_CLIP_AUTOPLAY}>
						<source src="/ebay-color-tool.mov" />
						<Showcase
							images={[data.colorLayeringTool.childImageSharp.fluid]}
							layout={SHOWCASE_LAYOUT_1UP}
						/>
					</VideoLoop>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="82.5rem">
					<Showcase
						images={[
							data.colorBlocker1.childImageSharp.fluid,
							data.colorBlocker2.childImageSharp.fluid,
						]}
						layout={SHOWCASE_LAYOUT_2UP}
					/>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							In addition, we built tools that can output production-ready
							creative assets with ease. Not only did this reduce the waste in
							the manual efforts that were required to design highly templatized
							elements, but ensured that all assets complied with the brand
							standards.
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={1920} height={1280} mode={VIDEO_CLIP_AUTOPLAY}>
						<source src="/ebay-asset-maker-poster-proto.mov" />
						<Showcase
							images={[data.assetMaker.childImageSharp.fluid]}
							layout={SHOWCASE_LAYOUT_1UP}
						/>
					</VideoLoop>
				</Container>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<blockquote>
								<p className="--large">
									“FA helped us create a powerful set of tools that removed
									guesswork and empowered people to bring the eBay brand to life
									in ways we never imagined.”
								</p>
								<footer>
									<p className="--small">
										<strong>Dave Lippman</strong>
										<br />
										VP, Design
									</p>
								</footer>
							</blockquote>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">Next</p>
							<p className="--headline">
								<StandaloneLink to={nextPage.to}>
									{nextPage.title}
								</StandaloneLink>
							</p>
							<p className="--small">
								<InlineLink to="/work">All Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</div>
	)
}

export default provideAccentTheme(EBayPage, THEME_GREEN)
